$transition-duration-shortest: 0.05s;
$transition-duration-shorter: 0.15s;
$transition-duration-short: 0.3s;
$transition-duration-medium: 0.5s;
$transition-duration-long: 1s;
$transition-easing: ease-in-out;
$transition: $transition-duration-short $transition-easing;

.transition-shortest {
  transition: all $transition-duration-shortest $transition-easing;
}

.transition-shorter {
  transition: all $transition-duration-shorter $transition-easing;
}

.transition-short,
%transition-short {
  transition: all $transition-duration-short $transition-easing;
}

.transition,
%transition {
  transition: all $transition-duration-medium $transition-easing;
}

.transition-medium {
  transition: all $transition-duration-medium $transition-easing;
}

.transition-long {
  transition: all $transition-duration-long $transition-easing;
}

$transition-animate-appear-from-top: animate-appear-from-top 0.55s;

@keyframes animate-appear-from-top {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}

$transition-animate-disappear-to-top: animate-disappear-to-top 0.55s;

@keyframes animate-disappear-to-top {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2rem);
  }
}

$transition-animate-marquee: animate-marquee 100s linear infinite;

@keyframes animate-marquee {
  0% { 
    transform: translate(0, 0); 
  }

  100% {
    transform: translate(-100%, 0); 
  }
}
