// BORDERS
$border-black: 1px solid color('black');
$border-green: 1px solid color('green');
$border-white: 1px solid color('white');

.border-black {
  border: $border-black;
}

.border-top-black {
  border-top: $border-black;
}

.border-bottom-black {
  border-bottom: $border-black;
}

.border-right-black {
  border-right: $border-black;
}

.border-left-black {
  border-left: $border-black;
}

.border-green {
  border: $border-green;
}

.border-white {
  border: $border-white;
}

.border-top-white {
  border-top: $border-white;
}

.border-bottom-white {
  border-bottom: $border-white;
}

.border-right-white {
  border-right: $border-white;
}

.border-left-white {
  border-left: $border-white;
}

//GLOBAL VIEW AND PADDING
.view {
  @include media('md-up') {
    padding-top: 23.8125rem;
  }

  @include media('xl-lg-up') {
    padding-top: $nav-notification-fully-open-desktop;
  }

  &--primary-nav-active-notification-marquee-active {
    padding-top: $primary-nav-notification-marquee-height-tablet;

    @include media('xl-lg-up') {
      padding-top: $primary-nav-notification-marquee-height-desktop;
    }
  }

  &----notification-bar-inactive-main-menu-active {
    padding-top: $primary-nav-height-sm-up;

    @include media('xl-lg-up') {
      padding-top: $nav-subnav-height-desktop;
    }
  }

  &--notification-bar-secondary-nav-inactive {
    padding-top: $primary-nav-height-sm-up;

    @include media('xl-lg-up') {
      padding-top: $primary-nav-height-desktop;
    }
  }

  &--notification-bar-secondary-nav-active {
    padding-top: $primary-nav-height-xs;

    @include media('sm-up') {
      padding-top: $primary-nav-height-sm-up;
    }

    @include media('md-up') {
      padding-top: $nav-notification-fully-open-tablet;
    }

    @include media('xl-lg-up') {
      padding-top: $nav-notification-fully-open-desktop;
    }
  }

  &--notification-bar-inactive {
    padding-top: $primary-nav-height-xs;

    @include media('sm-up') {
      padding-top: $primary-nav-height-sm-up;
    }

    @include media('md-up') {
      padding-top: 5.3796875rem;
    }

    @include media('xl-lg-up') {
      padding-top: $nav-subnav-height-desktop;
    }
  }

  > .GenericPageView > :last-child,
  > .ArticleView > :last-child {
    border-bottom: $border-black;
  }
}

.site-padding-x {
  padding-left: $site-padding-x-sm;
  padding-right: $site-padding-x-sm;

  @include media('md-up') {
    padding-left: $site-padding-x-md;
    padding-right: $site-padding-x-md;
  }
}

.site-padding-y {
  padding-bottom: $site-padding-x-sm;

  @include media('md-up') {
    padding-bottom: $site-padding-x-md;
  }
}

.site-margin-x {
  margin-left: $site-padding-x-sm;
  margin-right: $site-padding-x-sm;

  @include media('md-up') {
    margin-left: $site-padding-x-md;
    margin-right: $site-padding-x-md;
  }
}

// GRID BACKGROUND, BLACK
$columns-black: linear-gradient(to right, color('black') 1px, transparent 1px);

$baseline-xs-black: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent 5rem
);

$baseline-sm-xs-black: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent 6rem
);

$baseline-md-black: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent 4rem
);

$baseline-lg-black: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent 5.5rem
);

$baseline-xl-black: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent 7rem
);

$baseline-xxl-black: repeating-linear-gradient(
  to bottom,
  color('black'),
  color('black') 1px,
  transparent 0,
  transparent 9rem
);

.grid-black-3-4-columns {
  background-size: 33.33333%;
  background-position: 0 0;
  z-index: -1;
  background-image: $columns-black, $baseline-xs-black;

  @include media('md-up') {
    background-size: 25%;
    background-image: $columns-black, $baseline-md-black;
  }

  @include media('lg-up') {
    background-image: $columns-black, $baseline-lg-black;
  }

  @include media('xl-up') {
    background-image: $columns-black, $baseline-xl-black;
  }

  @include media('xxl-up') {
    background-image: $columns-black, $baseline-xxl-black;
  }
}

.grid-black-12-columns {
  background-size: 25%;
  background-position: 0 0;
  z-index: -1;
  background-image: $columns-black, $baseline-xs-black;

  @include media('sm-xs-up') {
    background-size: calc(100% / 6);
    background-image: $columns-black, $baseline-sm-xs-black;
  }

  @include media('md-up') {
    background-size: calc(100% / 12);
    background-image: $columns-black, $baseline-md-black;
  }

  @include media('lg-up') {
    background-size: calc(100% / 12);
    background-image: $columns-black, $baseline-lg-black;
  }

  @include media('xl-up') {
    background-size: calc(100% / 12);
    background-image: $columns-black, $baseline-xl-black;
  }

  @include media('xxl-up') {
    background-size: calc(100% / 12);
    background-image: $columns-black, $baseline-xxl-black;
  }
}

// GRID BACKGROUND, WHITE
$columns-white: linear-gradient(to right, color('white') 1px, transparent 1px);

$baseline-xs-white: repeating-linear-gradient(
  to bottom,
  color('white'),
  color('white') 1px,
  transparent 0,
  transparent 5rem
);

$baseline-sm-xs-white: repeating-linear-gradient(
  to bottom,
  color('white'),
  color('white') 1px,
  transparent 0,
  transparent 6rem
);

$baseline-md-white: repeating-linear-gradient(
  to bottom,
  color('white'),
  color('white') 1px,
  transparent 0,
  transparent 4rem
);

$baseline-lg-white: repeating-linear-gradient(
  to bottom,
  color('white'),
  color('white') 1px,
  transparent 0,
  transparent 5.5rem
);

$baseline-xl-white: repeating-linear-gradient(
  to bottom,
  color('white'),
  color('white') 1px,
  transparent 0,
  transparent 7rem
);

$baseline-xxl-white: repeating-linear-gradient(
  to bottom,
  color('white'),
  color('white') 1px,
  transparent 0,
  transparent 9rem
);

.grid-white {
  background-size: 25%;
  background-position: 0 0;
  z-index: -1;
  background-image: $columns-white, $baseline-xs-white;

  @include media('sm-xs-up') {
    background-size: calc(100% / 6);
    background-image: $columns-white, $baseline-sm-xs-white;
  }

  @include media('md-up') {
    background-size: calc(100% / 12);
    background-image: $columns-white, $baseline-md-white;
  }

  @include media('lg-up') {
    background-size: calc(100% / 12);
    background-image: $columns-white, $baseline-lg-white;
  }

  @include media('xl-up') {
    background-size: calc(100% / 12);
    background-image: $columns-white, $baseline-xl-white;
  }

  @include media('xxl-up') {
    background-size: calc(100% / 12);
    background-image: $columns-white, $baseline-xxl-white;
  }
}

.author-list {
  @include media('lg-up') {
    top: -1.5rem;

    > span {
      padding-top: 1.5rem;
    }
  }

  @include media('xxl-up') {
    top: -1.625rem;
    > span {
      padding-top: 1.625rem;
    }
  }
}

//ARTICLE
.text-article-body > p,
blockquote {
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @include media('xl-lg-up') {
    padding-left: 9.375rem;
    padding-right: 37.5625rem;
  }
}

.text-article-body > ul {
  margin-left: 4.25rem;

  @include media('xl-lg-up') {
    margin-left: 12.125rem;
    margin-right: 37.5625rem;
  }
}

.text-article-body > ol {
  margin-left: 2.75rem;

  @include media('xl-lg-up') {
    margin-left: 12.125rem;
    margin-right: 37.5625rem;
  }
}

.article-content-col-6 > div > p,
.article-content-col-6 > div > blockquote,
.article-content-col-6 > div > h1,
.article-content-col-6 > div > h2,
.article-content-col-6 > div > h3,
.article-content-col-6 > div > h4,
.article-content-col-6 > div > h5,
.article-content-col-6 > div > h6 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @include media('xl-lg-up') {
    padding-left: 22.8125rem;
    padding-right: 22.8125rem;
  }
}

.article-content-col-6 > div > ul,
.article-content-col-6 > div > ol {
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @include media('xl-lg-up') {
    margin-left: 25.5625rem;
    margin-right: 25.5625rem;
  }
}

.article-links-container {
  @include media('xl-lg-up') {
    margin-right: 8rem;
  }
}

.safe-visibility-hidden {
  position: absolute;
  text-indent: -10000px;
  white-space: nowrap;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.hyphens {
  hyphens: auto;

  @include media('lg-up') {
    hyphens: none;
  }
}

.topics-title-container-padding {
  padding: 0.625rem 0.625rem 2.75rem 0.625rem;

  @include media('md-up') {
    padding: 0.9375rem 0.9375rem 6.625rem 0.9375rem;
  }
}

.block-title-container-padding {
  padding: 0.5rem 0.5625rem 0.6875rem 0.5625rem;

  @include media('md-up') {
    padding: 0.625rem;
  }
}

.load-more-container-padding {
  padding: 0.5rem 0.5625rem 0.6875rem 0.5625rem;

  @include media('md-up') {
    padding: 0.625rem 0.625rem 2.5rem 0.625rem;
  }
}

//IMAGES

.image-drop-shadow {
  box-shadow: 19px 0px 30px 0px rgba(0, 0, 0, 0.13);
}
