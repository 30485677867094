.ImgWithHover {
  &__reflection-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    transform-style: preserve-3d;
    perspective: 1000px;
  }

  &__reflection-content {
    background-size: cover;
    background-position: center;
    transform: rotateX(0) rotateY(0);
    pointer-events: none;
    transition: 100ms linear transform;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      width: 200%;
      height: 200%;
      left: -50%;
      top: -50%;
    }
  }

  &__reflection-grid-cell {
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
  }
}

@for $r from 1 to 11 {
  @for $c from 1 to 11 {
    .ImgWithHover__reflection-grid-cell-#{( ($r*10) + $c - 10)} {
      top: ($r * 10%)-10%;
      left: ($c * 10%)-10%;
    }
    .ImgWithHover__reflection-grid-cell-#{( ($r*10) + $c - 10)}:hover
      ~ .ImgWithHover__reflection-content {
      transform: rotateX((($r * -5)+25deg)) rotateY((-25deg+ ($c * 5)));
      &:before {
        transform: translateY(25- (5% * $r));
      }
    }
  }
}
