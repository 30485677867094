.GalleryBlock {
  &__block-container {
    overflow-x: scroll;
  }

  &__image-container {
    height: 17.5625rem;

    @include media('sm-down') {
      border-top: $border-black;
    }

    @include media('md-up') {
      height: 16rem;
    }

    @include media('lg-md-up') {
      height: 22rem;
    }

    @include media('xl-up') {
      height: 28rem;
    }
  }

  &__image {
    object-fit: contain;
    max-height: 17rem;
    max-width: 13rem;

    @include media('md-up') {
      max-height: 15rem;
      max-width: 12rem;
    }

    @include media('lg-md-up') {
      max-height: 17rem;
      max-width: 14rem;
    }

    @include media('lg-up') {
      max-height: 20rem;
      max-width: 17rem;
    }

    @include media('xl-up') {
      max-height: 26rem;
      max-width: 21rem;
    }

    @include media('xxl-up') {
      max-width: 25rem;
    }
  }

  &__title-container > span {
    @include media('md-down') {
      font-size: 0.9375rem;
      line-height: 1.0625rem;
    }
  }

  &__title-container {
    height: 3.875rem;

    @include media('sm-down') {
      border-bottom: 0;
    }

    @include media('md-up') {
      padding: 0.625rem;
      height: 9.25rem;
    }

    @include media('xl-up') {
      height: 7.5rem;
    }
  }
}
