@import 'settings';

$spacing-units: 0rem, 0.25rem, 0.4rem, 0.5rem, 0.625rem, 0.75rem, 0.9375rem, 1rem, 1.2rem,
  1.25rem, 1.375rem, 1.5rem, 1.75rem, 2rem, 2.5rem, 2.75rem, 3rem, 3.75rem, 4rem, 5rem,
  6rem, 7rem;

$aspect-ratios: (
  'square': (
    1,
    1,
  ),
  'landscape': (
    16,
    9,
  ),
  'portrait': (
    3,
    2,
  ),
);

$breakpoints: (
  'xs': 375px,
  'sm-xs': 500px,
  'sm': 640px,
  'md': 768px,
  'lg-md': 900px,
  'lg': 1080px,
  'xl-lg': 1180px,
  'xl': 1280px,
  'xxl': 1440px,
  'xxxl': 1600px,
);

$additional-class-definitions: (
  'debug': (
    border: 1px solid red,
  ),
  'text-decoration-none': (
    text-decoration: none,
  ),
  'flex-grow-1': (
    flex-grow: 1,
  ),
  'flex-grow-2': (
    flex-grow: 2,
  ),
);
