//Global
$site-padding-x-sm: 0.5rem; 
$site-padding-x-sm-double: 1rem; 
$site-padding-x-md: 0.625rem;
$site-padding-x-md-double: 1.375rem;

//Nav and Notification Bar
$primary-nav-height-xs: 5rem;
$primary-nav-height-sm-up: 3.125rem;
$primary-nav-height-xl-lg-up: 4.375rem;
$primary-nav-height-desktop: 3.75rem;
$nav-subnav-height-desktop: 8.125rem;
$nav-notification-fully-open-tablet: 24.6915625rem;
$nav-notification-fully-open-desktop: 27.436875rem;
$full-notification-height-desktop: 19.311875rem;
$notification-marquee-height-desktop: 3.75rem;
$notification-banner-height-desktop: 15.5625rem;
$primary-nav-notification-marquee-height-tablet: 6.875rem;
$primary-nav-notification-marquee-height-desktop: 8.125rem;

//Article
$article-inner-content-padding-x-sm: 1.3125rem;
