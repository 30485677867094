.ArticleHero {
  &__image-container {
    height: 75vh;

    > img {
      max-height: 20rem;
      object-fit: contain;

      @include media('lg-up') {
        max-height: 25rem;
      }

      @include media('xl-up') {
        max-height: 40rem;
      }
    }
  }

  &__author {
    @include media('lg-up') {
      top: -1rem;
    }

    @include media('xl-up') {
      top: -1.625rem;
    }
  }
}
