.AuthorList {

  &--style-white {
    &__button:hover {
      color: color('black');
    }
  }

  &--style-black {
    &__button:hover {
      color: color('white');
    }
  }
}
