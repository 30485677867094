.ArticleEventBlock {
  padding-bottom: 3.4275rem;

  @include media('lg-up') {
    padding-bottom: 8.3125rem;
  }

  &__header {
    padding: 1.0625rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    
    @include media('lg-up') {
      margin-top: 3.5625rem;
      margin-bottom: 3.5625rem;
    }
  }

  &__details {
    padding-bottom: 3.875rem;
  }

  &__description {
    p {
      margin-bottom: 1rem;

      > a {
        text-decoration: none;
        color: color('bronze');
      }
    }
  }
}
