.Quote {
  font-size: 2.1875rem;
  line-height: 2.4375rem;
  padding-top: 3.4375rem;
  padding-bottom: 3.4375rem;

  @include media('md-up') {
    font-size: 3.125rem;
    line-height: 3.4375rem;
  }

  @include media('lg-up') {
    font-size: 5rem;
    line-height: 5.5rem;
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
}
