.Nav {
  &__primary-nav {
    height: $primary-nav-height-xs;

    @include media('sm-up') {
      height:$primary-nav-height-sm-up;
    }

    @include media('xl-lg-up') {
      height: $primary-nav-height-xl-lg-up;
    }

    &--menu-active {
      height: $primary-nav-height-xs;

      @include media('sm-up') {
        height: $primary-nav-height-sm-up;
      }

      @include media('md-up') {
        height: 5.3796875rem;
      }

      @include media('xl-lg-up') {
        height: $nav-subnav-height-desktop;
      }
    }

    &--secondary-nav-active {
      height: 4.3125rem;

      @include media('sm-up') {
        height: $primary-nav-height-sm-up;
      }

      @include media('xl-lg-up') {
        height: 4.375rem;
      }
    }
  }

  &--full-notification-active {
    @include media('md-up') {
      top: $full-notification-height-desktop;
    }
  }

  &--notification-marquee-active {
    @include media('md-up') {
      top: $notification-marquee-height-desktop;
    }
  }

  &__main-menu {
    &--closed {
      transform: translate3d(0, -2rem, 0);
      transform-style: preserve-3d;
      height: 0;
    }
  }

  &__main-menu-inner-container {
    @include media('md-up') {
      border-top: $border-black;
    }
  }

  &__secondary-nav-mobile {
    height: $primary-nav-height-sm-up;

    @include media('md-up') {
      margin-left: -0.15rem;
    }

    @include media('lg-up') {
      margin-left: -0.25rem;
    }

    @include media('xl-lg-up') {
      height: 4.375rem;
    }
  }

  &__secondary-nav {
    margin-left: -0.15rem;

    @include media('sm-up') {
      height: 2.2546875rem;
    }

    @include media('md-up') {
      margin-left: -0.15rem;
    }

    @include media('lg-up') {
      margin-left: -0.25rem;
    }

    @include media('xl-lg-up') {
      height: 3.75rem;
    }

    &--closed {
      transform: translate3d(0, -3.75rem, 0);
      transform-style: preserve-3d;
      pointer-events: none;
      opacity: 0;
    }

    &--mobile {
      padding-bottom: 0.625rem;
      margin-bottom: 0.625rem;
    }
  }

  &__logo-container {
    height: 1.48rem;
    margin-top: 0.10rem;

    @include media('sm-up') {
      margin-top: 0;
    }

    @include media('xl-lg-up') {
      height: 2.5rem;
    }
  }

  &__button {
    &:hover {
      color: color('black');
    }
  }

  &__menu-button {
    &:hover {
      color: color('bronze-darker');
    }
  }
}
