.ImageCarousel {
  width: calc(100vw - #{$site-padding-x-sm-double} - 1);

  @include media('md-up') {
    width: calc(100vw - #{$site-padding-x-md-double} - 1);
  }

  &__container {
    outline: none;
    position: relative;

    &--multi-image {
      .flickity-prev-next-button {
        &.previous {
          left: 0;
          cursor: url('/assets/images/carousel-left-arrow.svg'), w-resize;
        }

        &.next {
          right: 0;
          cursor: url('/assets/images/carousel-right-arrow.svg'), e-resize;
        }
      }
    }

    .flickity-prev-next-button {
      position: absolute;
      width: 10%;
      height: 100%;
      top: 0;
      opacity: 0;
    }

    .flickity-slider {
      height: 100%;
      display: flex;
      align-items: center;

      > img {
        display: flex;
      }
    }
  }

  &__img {
    @include media('sm-down') {
      width: 80vw;
    }

    @include media('md-up') {
      max-height: 35rem;
    }
  }

  &__caption-container {
    min-height: 5.0625rem;
  }
}
