.NotificationBar {
  transform: translate3d(0, -$primary-nav-height-desktop, 0);

  &--active {
    transform: translate3d(0, 0, 0);
  }

  &__banner {
    overflow: hidden;
    padding: 0;
    height: 0;

    &--active {
      height: $notification-banner-height-desktop;
    }
  }

  &__marquee {
    height: $notification-marquee-height-desktop;

    &__text {
      padding-left: 100%;
      animation: $transition-animate-marquee;
      white-space: nowrap;

      &:hover {
        animation-play-state: paused;
      }
    }
  }

  &__gradient {
    background: color('black');
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.15396126760563378) 18%,
      rgba(0, 0, 0, 1) 40%
    );
  }

  &__image-container {
    max-height: 13rem;
    object-fit: contain;
    width: auto;
  }
}
