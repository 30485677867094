.Hero {
  &__image-container {
    height: 75vh;
  }

  &__image {
    max-height: 20rem;
    max-width: 80vw;
    object-fit: contain;

    @include media('lg-up') {
      max-height: 30rem;
      max-width: 90vw;
    }

    @include media('xl-up') {
      max-height: 40rem;
    }
  }
}
