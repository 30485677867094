.LinkList {
  &--style-article {
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    &__link-title {
      @include media('lg-up') {
        padding-bottom: 2.375rem;
      }
    }
  }

  &__link-title, &__link {
    @extend .text-md-mobile;

    @include media('md-up') {
      font-size: 1.875rem;
      line-height: 2.125rem;
    }
  }

  &__links-container {
   > a > div:hover {
      color: color('black');
    }
  }
}
