.Video {
  &__title-inner-container {
    @include media('lg-down') {
      flex: display;
      flex-direction: column;
    }
  }

  &__title-container {
    padding: 1rem 1rem 0.5rem 1rem;

    @include media('md-up') {
      padding: 1.8rem 1.2rem 1.2rem 1.2rem;
    }
  }

  &__video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  &__video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
}
