.NavFooterMenu {
  border-top: $border-black;
  margin-top: 0.1875rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;

  &__footer-icon-container {
    width: 9.625rem;
  }

  &__link {
    > span:hover {
      font-style: italic;
    }
  }
}
