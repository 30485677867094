.ArticleStudentWorkBlock {
  &__image-container {
    > img {
      height: auto;
      width: 100%;
      object-fit: contain;
    }
  }

  &__block {
    height: auto;

    &:nth-of-type(3n + 1) {
      left: 0;

      @include media('md-up') {
        left: 0;
      }

      @include media('xxl-up') {
        left: -1%;
      }
    }

    &:nth-of-type(3n + 2) {
      left: 5%;

      @include media('sm-up') {
        left: 18%;
      }

      @include media('md-up') {
        left: 27%;
      }

      @include media('xxl-up') {
        left: 50%;
      }
    }

    &:nth-of-type(3n + 3) {
      right: 3%;

      @include media('md-up') {
        left: 15%;
      }
    }

    &:nth-of-type(3n + 4) {
      left: 0;

      @include media('md-up') {
        left: 20%;
      }

      @include media('xxl-up') {
        left: 35%;
      }
    }

    &:nth-of-type(3n + 5) {
      left: 5%;

      @include media('md-up') {
        left: 0;
      }

      @include media('xxl-up') {
        left: 2%;
      }
    }

    &:nth-of-type(3n + 6) {
      right: 2%;

      @include media('md-up') {
        left: 28%;
      }

      @include media('xxl-up') {
        left: 45%;
      }
    }
  }

  &__caption {
    transform: translate3d(0, -0.75rem, 0);

    &--is-active {
      transform: translate3d(0, -0.25rem, 0);
    }
  }
}
