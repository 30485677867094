.SearchComponent {
  &__search-field {
    padding-top: 0.8125rem;
    padding-bottom: 1rem;

    @include media('md-up') {
      padding-top: 1rem;
      padding-bottom: 1.8125rem;
    }
  }

  &__no-results-container {
    flex-grow: 5;
  }

  &__search-results {    
    &--no-results {
      min-height: 25rem;

      @include media('lg-up') {
        min-height: 35rem;
      }
    }
  }

  &__research-header {
    padding: 0.875rem 0.75rem 0.875rem 0.75rem;

      @include media('md-up') {
        padding: 3rem 0.75rem 1.0625rem 0.75rem;
      }
  }
}
